<footer class="footer">
  <div class="container py-40px">
    <div class="row justify-content-center">
      <div class="order-md-1 order-sm-3 order-2 col-md-4 col-12 flex-column justify-content-between pl-3">
        <div class="text-md-left text-sm-center text-left">
          <img src="/assets/images/logo/white_text.svg" width="122" class="mb-30 d-none d-md-flex"/>
          <div class="font-color-border-color line-h-24px d-none d-md-flex">
            {{'Footer.SimplifyYourBusiness.FirstLine' | translate}}
            <br />
            {{'Footer.SimplifyYourBusiness.SecondLine' | translate}}
          </div>
          <div class="pt-md-18 pt-40px footer-link-color line-h-normal">
            {{ (isUz ? 'Footer.IdentityCodeText_UZ' : 'Footer.IdentityCodeText') | translate}}
           </div>
          <address class="pt-6 footer-link-color mb-0">
            {{(isUz ? 'Footer.AddressLocationText_UZ' : 'Footer.AddressLocationText') | translate}}
          </address>
        </div>
        <div></div>
      </div>
      <div class="order-md-2 order-sm-1 col-sm-5 col-md-4 justify-content-lg-end justify-content-md-center d-none d-sm-flex justify-content-sm-end pl-sm-30 pl-lg-15">
        <div class="px-0">
          <div class="font-family-semibold font-size-16 mb-36">{{'Footer.ProductHeading' | translate}}</div>
          <nav class="footer-nav">
            <a class="footer-link d-block mb-36" [routerLink]="'/' + translateService.currentLang">{{'MainNavigation.Home' | translate}}</a>
            <a class="footer-link d-block mb-36"
              [routerLink]="'/' + translateService.currentLang+(isUz ? '/dokonni-avtomatlashtirish-paketlar' : '/magaziis-programa-paketebi')">{{'MainNavigation.Packages' | translate}}</a>
            <a class="footer-link d-block mb-36" [routerLink]="'/' + translateService.currentLang+'/faq/general'">{{'GENERAL.FAQ' | translate}}</a>
            <a class="footer-link d-block" href="https://optimo.ge/blog/" target="_blank" *ngIf="!isUz">{{'GENERAL.Blog' | translate}}</a>
          </nav>
          <div class=""></div>
        </div>

      </div>
      <div class="order-md-3 order-sm-2 order-1 col-sm-5 col-md-4 d-flex justify-content-md-end justify-content-sm-center flex-sm-row flex-column pr-lg-15 pr-23">
        <div>
          <div class="font-family-semibold font-size-16 mb-33 d-none d-sm-flex">{{'Footer.ContactHeading' | translate}}
          </div>
          <nav class="footer-nav">
            <a class="footer-link d-block mb-sm-4 mb-3" href="tel:{{phoneNumber}}" (click)="onPhoneNumber()">
              <app-icon icon="footer-phone" class="mr-4"></app-icon>
              <span>{{phoneNumber}}</span>
            </a>
            <a class="footer-link d-block mb-sm-4 mb-3" href="{{isUz ? 'mailto:info@optimo.uz' : 'mailto:info@optimo.ge'}}">
              <app-icon icon="footer-email" class="mr-4"></app-icon>
              <span>{{isUz ? 'info@optimo.uz' : 'info@optimo.ge'}}</span>
            </a>
            <a class="footer-link d-block mb-sm-4 mb-3"
               href="{{isUz ? 'https://www.facebook.com/optimouzbekistan' : 'https://www.facebook.com/OptimoPOS'}}"
               target="_blank">
              <app-icon icon="facebook" class="mr-4"></app-icon>
              <span>Facebook</span>
            </a>
            <a class="footer-link d-block mb-sm-4 mb-3"
               *ngIf="isUz"
               href='https://www.instagram.com/optimo_uz'
               target="_blank">
              <app-icon icon="instagram" class="mr-4"></app-icon>
              <span>Instagram</span>
            </a>
            <a class="footer-link d-block"
               *ngIf="isUz"
               href='https://t.me/optimo_uz'
               target="_blank">
              <app-icon icon="telegram" class="mr-4"></app-icon>
              <span>Telegram</span>
            </a>
            <a *ngIf="!isUz" class="footer-link d-block" href="https://www.linkedin.com/company/optimobusiness/about/"
              target="_blank">
              <app-icon icon="linkedin" class="mr-4"></app-icon>
              <span>Linkedin</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div
    class="py-sm-0 py-1 footer-border-top pb-53 pb-sm-0">
     <span class="d-flex align-items-center justify-content-center text-center flex-column flex-sm-row font-size-14 font-size-sm-16
     font-color-footer-color line-h-24px h-88px">
       <span>© {{currentYear}} {{ isUz ? 'Optimo.uz' : 'Optimo.ge'}}</span><span class="d-none d-sm-inline">&nbsp;&nbsp;</span><span>{{'GENERAL.AllRightsReserved'
      | translate}}</span>
     </span>

  </div>
</footer>
