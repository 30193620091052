<div class="landing-watch-video-modal">
  <ng-container *ngIf="!isUz; else uzVideo">
    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Ae-8oPCUDbU" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
  </ng-container>
  <ng-template #uzVideo>
    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/c6Lcn_k1QMw?si=YPJQrEa09mcrrhqo"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
  </ng-template>
</div>

