<div class="d-flex align-items-center py-3 pl-3 pr-6" (click)="onOpenLanguage()">
  <div class="d-flex">
    <app-icon [icon]="icon"></app-icon>
  </div>
  <ng-select
    [isOpen]="isOpen"
    [searchable]="false"
    [clearable]="false"
    [ngModel]="translateService.currentLang"
    (ngModelChange)="onLanguageChange($event)"
  >

    <ng-option value='ka' class="d-flex align-items-center" *ngIf="isGe">
      <span class="font-size-16 font-family-regular pl-1">GE</span>
    </ng-option>
    <ng-option value='uz' class="d-flex align-items-center" *ngIf="isUz">
      <span class="font-size-16 font-family-regular pl-1">UZ</span>
    </ng-option>
    <ng-option value='en' class="d-flex align-items-center">
      <span class="font-size-16 font-family-regular pl-1">EN</span>
    </ng-option>
    <ng-option value='ru' class="d-flex align-items-center">
      <span class="font-size-16 font-family-regular pl-1">RU</span>
    </ng-option>
  </ng-select>

</div>
