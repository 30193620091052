import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

export interface IconPieChartData {
  primaryColor?: string;
  secondaryColor?: string;
  rotate?: number;
}

@Component({
  selector: 'app-icon-pie-cart',
  templateUrl: './icon-pie-chart.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPieChartComponent {
  private _data: IconPieChartData;
  @Input()
  set data(value: IconPieChartData) {
    this._data = {
      primaryColor: '#4562FF',
      secondaryColor: '#27D3A2',
      rotate: 0,
      ...value,
    };
  }
  get data(): IconPieChartData {
    return this._data;
  }
  constructor(private sanitizer: DomSanitizer) {}

  get transform(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `rotate(${this.data.rotate}deg) scale(1, -1)`,
    );
  }
}
