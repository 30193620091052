<div
  class="contact-info-wrapper d-flex flex-wrap align-items-center justify-content space-between rounded border col-12 px-32 px-sm-0 px-md-40px py-sm-18 py-4"
>
  <div
    class="col-12 col-sm d-flex align-items-center px-0 px-sm-15 mb-4 mb-sm-0"
  >
    <app-icon icon="phone-large"></app-icon>
    <div class="ml-3">
      <div class="font-size-14 text-muted mb-2px">{{"GENERAL.Tel" | translate}}</div>
      <a
        href="tel:{{phoneNumber}}"
        class="font-size-16 text-dark text-decoration-none"
        (click)="onPhoneNumber()"
        >{{phoneNumber}}</a
      >
    </div>
  </div>
  <div
    class="col-12 col-sm d-flex align-items-center px-0 px-sm-15 mb-4 mb-sm-0"
  >
    <app-icon icon="email-large"></app-icon>
    <div class="ml-3">
      <div class="font-size-14 text-muted mb-2px">{{"GENERAL.Email" | translate}}</div>
      <a
        href="{{isUz ? 'mailto:info@optimo.uz' : 'mailto:info@optimo.ge'}}"
        class="font-size-16 text-dark text-decoration-none"
        >{{isUz ? 'info@optimo.uz' : 'info@optimo.ge'}}</a
      >
    </div>
  </div>
  <div class="col-12 col-sm d-flex align-items-center px-0 px-sm-15">
    <app-icon icon="location-large"></app-icon>
    <div class="ml-3">
      <div class="font-size-14 text-muted mb-2px">{{"GENERAL.Address" | translate}}</div>
      <div class="font-size-16 text-dark">{{(isUz ? 'PackagesPage.Contact.AddressValue_UZ' : 'PackagesPage.Contact.AddressValue') | translate}}</div>
    </div>
  </div>
</div>
