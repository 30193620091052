import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isUz } from '../../../config/ui-config';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private _metaService: Meta,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public setTitleAndMetaTags() {
    const params = this._route.root.firstChild.snapshot.data as {
      title: string;
      meta: string;
    };
    if (params.title && params.meta) {
      const title: string =
        this._translateService.instant(params.title) + ' - Optimo';
      const metaContent: string = this._translateService.instant(params.meta);
      this._titleService.setTitle(title);
      this._metaService.updateTag({
        name: 'description',
        content: metaContent,
      });
      this._metaService.updateTag({
        property: 'og:description',
        content: metaContent,
      });
      this._metaService.updateTag({
        property: 'og:title',
        content: title,
      });
      const domain = isUz ? 'optimo.uz' : 'optimo.ge';
      this.document.documentElement.lang = this._translateService.currentLang;
      this._metaService.updateTag({
        property: 'og:url',
        content: `https://${domain}/`,
      });
      this._metaService.updateTag({
        property: 'og:locale',
        content: isUz ? 'uz_Cyrl' : 'ka_GE',
      });
      this._metaService.updateTag({
        property: 'og:image:secure_url',
        content: `https://cdn.${domain}/images/share.png`,
      });
      this._metaService.updateTag({
        property: 'og:image',
        content: `https://cdn.${domain}/images/share.png`,
      });
    }
  }

  pushToDataLayer(event: any) {
    const dataLayer = (window as any).dataLayer;
    if (!dataLayer) {
      console.error('window.dataLayer not found', dataLayer);
      return;
    }
    dataLayer.push(event);
  }
}
