import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { CookieService } from '../helpers/cookie.service';
import { Title } from '@angular/platform-browser';

export enum Languages {
  EN = 'en',
  KA = 'ka',
  RU = 'ru',
  UZ = 'uz',
}

export enum LocalStorageKeys {
  USER_LANG = 'user-language-temp',
}

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private languagesList = ['en', 'ka', 'ru'];

  private _currentLanguage: Languages;

  public get currentLanguage(): Languages {
    if (!this._currentLanguage) this.init();
    return this._currentLanguage;
  }

  public set currentLanguage(lang: Languages) {
    this._currentLanguage = lang;
    this.storageService.set(
      LocalStorageKeys.USER_LANG,
      this.currentLanguage,
      true,
    );

    this.cookieService.set('selectedLanguage', lang, 365);
    this.translateService.use(this.currentLanguage);
    const translations =
      this.translateService.translations[this.translateService.currentLang];
    if (translations !== undefined) {
      this._titleService.setTitle(translations['GENERAL']['OPTIMO']);
    }
  }

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    private cookieService: CookieService,
    private _titleService: Title,
  ) {}

  public init(isUz = false): void {
    const envLang = this.translateService.defaultLang;
    if (isUz) {
      this.languagesList = ['en', 'uz', 'ru'];
    }
    this.translateService.addLangs(this.languagesList);
    this.translateService.setDefaultLang(envLang);
    const browserLang = envLang ?? this.translateService.getBrowserLang();
    switch (this.storageService.get(LocalStorageKeys.USER_LANG, true)) {
      case Languages.EN:
        this.currentLanguage = Languages.EN;
        break;
      case Languages.UZ && isUz:
        this.currentLanguage = Languages.UZ;
        break;
      case Languages.KA && !isUz:
        this.currentLanguage = Languages.KA;
        break;
      case Languages.RU:
        this.currentLanguage = Languages.RU;
        break;
      default:
        this.currentLanguage = (
          browserLang.match(isUz ? /en|uz|ru/ : /en|ka|ru/)
            ? browserLang
            : envLang
        ) as Languages;
        break;
    }
  }

  public switchLanguage(lang, reload = true) {
    const isValidLang = this.languagesList.includes(lang);
    this.currentLanguage = isValidLang
      ? lang
      : this.translateService.defaultLang;
    this.cookieService.set('selectedLanguage', lang, 365);
    if (reload) {
      window.location.reload();
    }
  }

  public get langs() {
    return this.languagesList;
  }
  public get defaultLang() {
    return this.translateService.defaultLang;
  }
}
