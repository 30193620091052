import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { isGe, isUz } from '../../../../config/ui-config';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
})
export class LanguageSwitcher {
  public isUz = isUz;
  public isGe = isGe;
  _requestButtonVisibility = false;
  public isOpen = false;
  @Input() icon: string;
  @Input() set requestButtonVisibility(value) {
    this._requestButtonVisibility = value;
  }
  get requestButtonVisibility() {
    return this._requestButtonVisibility;
  }
  constructor(
    public languageService: LanguageService,
    public translateService: TranslateService,
  ) {}

  onOpenLanguage() {
    this.isOpen = !this.isOpen;
  }

  public onLanguageChange(lang: string): void {
    this.languageService.languageChange(lang);
  }
}
