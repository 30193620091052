import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private roleKey =
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  constructor(private storage: StorageService) {}

  private decodeAccessToken(): unknown {
    const accesToken: string = this.storage.getAccessToken();
    return decode(accesToken);
  }

  public getUserRole(): string {
    const userData: unknown = this.decodeAccessToken();
    return userData[this.roleKey];
  }

  public isUser(roles: string[]): boolean {
    return roles.includes(this.getUserRole()) ? true : false;
  }
}
