import { Component } from '@angular/core';
import { RoutingStateService } from '@optimo/core';
import { LanguageService } from './shared/services/language.service';
import { packagesRoute } from '../config/ui-config';
import { LandingComponent } from './landing/landing.component';
import { FooterComponent } from './footer/footer.component';
import { IsInViewportDirective } from './shared/directives/is-in-viewport.directive';
import { VideoDialogComponent } from './landing/video-dialog/video-dialog.component';
import { RequestFormComponent } from './request/form/request-form.component';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { RouterOutlet } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconModule } from './icon/icon.module';
import { SharedModule } from './shared/shared.module';
import { LoaderBarModule } from '@optimo/ui-loader-bar';

import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FaqResolver } from './faq/faq.resolver';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CommonModule,
    NgxMaskPipe,
    NgxMaskDirective,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    IconModule,
    SharedModule,
    // NgxMaskModule.forRoot(),
    LoaderBarModule,
    // BrowserAnimationsModule,
    MatDialogModule,
    TranslateModule,
    LandingComponent,
    FooterComponent,
    IsInViewportDirective,
    VideoDialogComponent,
    RequestFormComponent,
    RouterOutlet,
  ],
  standalone: true,
  providers: [FaqResolver],
  // exports: [HeaderComponent],
  // bootstrap: [AppComponent],
})
export class AppComponent {
  constructor(
    public routingState: RoutingStateService,
    private _languageService: LanguageService,
  ) {
    this.initApp();
  }

  private initApp() {
    this.routingState.loadRouting(packagesRoute);
    this.routingState.trackGoogleAnalitics();
    this._languageService.init();
  }
}
