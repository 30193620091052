import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { SEOService } from './SEO.service';
import { defaultLanguage, languages } from '../../../config/ui-config';
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public languages: string[] = languages;
  public defaultLanguage = defaultLanguage;

  constructor(
    private _translateService: TranslateService,
    private _router: Router,
    private _SEOService: SEOService,
  ) {}

  public init() {
    this._translateService.addLangs(this.languages);
    this._translateService.setDefaultLang(this.defaultLanguage);
    this.listenRouter();
  }

  public languageChange(lang: string): void {
    const urlSegments = this._router.url.split('/');
    urlSegments[1] = lang;
    this._router.navigate([urlSegments.join('/')]);
  }

  private checkLanguage() {
    const validUrl = this._router.url.split('?')[0];
    const urlSegments = validUrl.split('/');
    const langFromUrl = urlSegments[1];
    const isValidLang = this.languages.includes(langFromUrl);
    const isEmptyRoute = urlSegments.every((x) => !x.length);
    if (!isValidLang && !isEmptyRoute) {
      this._router.navigate([!isValidLang ? '/' : `/${this.defaultLanguage}`]);
    } else if (langFromUrl !== this._translateService.currentLang) {
      this._translateService
        .use(langFromUrl ? langFromUrl : this.defaultLanguage)
        .toPromise()
        .then(() => {
          this._SEOService.setTitleAndMetaTags();
        });
    } else {
      this._SEOService.setTitleAndMetaTags();
    }
  }

  private listenRouter() {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkLanguage();
      });
  }
}
