import { CustomValidators } from '../app/core/helpers/validators/validators.helper';

export const isGe = true;
export const isUz = false;
export const phoneNumber = '(032) 2 800 900';

export const defaultLanguage = 'ka';
export const languages = ['ka', 'en', 'ru'];

export const currencyLabel = '₾';
export const currencyFormatDigits = 4;
export const currencyFormat = `1.${currencyFormatDigits}-${currencyFormatDigits}`;
export const currencyFormatDigitsShort = 2;
export const currencyFormatShort = `1.${currencyFormatDigitsShort}-${currencyFormatDigitsShort}`;

export const phoneNumberMinLength = 9;
export const phoneNumberMaxLength = 22;
export const phoneNumberValidator = CustomValidators.LocalPhoneNumber;
export const packagesRoute = 'magaziis-programa-paketebi';
export const MENUTREE = [
  {
    title: 'MainNavigation.Home',
    url: '/',
    target: '_self',
  },
  {
    title: 'MainNavigation.Packages',
    url: `/${packagesRoute}`,
  },
  {
    title: 'MainNavigation.Offers',
    url: '/offers',
  },
];
