import { Component } from '@angular/core';
import { SEOService } from '../../services/SEO.service';
import { isUz, phoneNumber } from '../../../../config/ui-config';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent {
  public isUz = isUz;
  public phoneNumber = phoneNumber;
  constructor(private seoService: SEOService) {}

  onPhoneNumber(): void {
    this.seoService.pushToDataLayer({
      event: 'phoneNumberClick',
      'event-uid': 'a473548b-289c-4b21-9d9c-6ad3e29dcf11',
      component: 'contact',
    });
  }
}
