import { Injectable, OnInit } from '@angular/core';
import decode from 'jwt-decode';
import { CookieService } from '../helpers/cookie.service';
import { LocalStorageService } from '../helpers/local-storage.service';
import { EOptimoProductType } from '../models/EOptimoProductType';

@Injectable({
  providedIn: 'root',
})
export class StorageService implements OnInit {
  EOptimoProductType = EOptimoProductType;

  private verifiedOtpToken = '';
  private accessToken: string;
  private secAccessToken: string;
  private userRole: string;
  private roleKey =
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  constructor(
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  set(key: string, value: any, global?: boolean): void {
    this.localStorageService.set(this.generateKey(key, global), value);
  }

  get(key: string, global?: boolean): any {
    return this.localStorageService.get(this.generateKey(key, global));
  }

  remove(key: string, global?: boolean): void {
    this.localStorageService.remove(this.generateKey(key, global));
  }

  private generateKey(key: string, global: boolean): string {
    if (global) {
      return key;
    }
    try {
      const tokenPayload: any = decode(
        this.secAccessToken ? this.secAccessToken : this.accessToken,
      );
      return (
        tokenPayload &&
        tokenPayload.sub &&
        tokenPayload.LegalEntityId &&
        `${tokenPayload.sub}__${tokenPayload.LegalEntityId}__${key}`
      );
    } catch {
      return key;
    }
  }

  getVerifiedOtpToken(): string {
    return this.verifiedOtpToken;
  }

  setVerifiedOtpToken(token = '') {
    this.verifiedOtpToken = token;
  }

  getAccessToken(): string {
    this.accessToken = this.cookieService.get('accessToken');
    return this.accessToken;
  }
  getSecAccessToken(): string {
    this.secAccessToken = this.cookieService.get('secAccessToken');
    return this.secAccessToken;
  }

  getUserRole(): number {
    this.userRole = this.cookieService.get('userRole');
    return +this.userRole;
  }

  isUserRole(role: string): boolean {
    const accessToken = this.getAccessToken();
    const tokenData = decode(accessToken);
    const storedRole = tokenData[this.roleKey].toString().toLowerCase();
    return storedRole === 'odin' ? true : storedRole === role.toLowerCase();
  }

  deleteUserRole(): void {
    return this.cookieService.delete('userRole');
  }

  deleteAccessToken(): void {
    return this.cookieService.delete('accessToken');
  }
  deleteSecAccessToken(): void {
    return this.cookieService.delete('secAccessToken');
  }

  getCheckingRefreshToken(): boolean {
    return JSON.parse(this.cookieService.get('isCheckingRefreshToken'));
  }

  setCheckingRefreshToken(value: boolean) {
    this.cookieService.set('isCheckingRefreshToken', JSON.stringify(value));
  }

  setAccessToken(token: string): void {
    this.cookieService.set('accessToken', token);
  }
  setSecAccessToken(token: string): void {
    this.cookieService.set('secAccessToken', token);
  }

  setUserRole(role: string): void {
    this.cookieService.set('userRole', role);
  }

  resetSpace() {
    this.localStorageService.remove('spaceId');
  }

  setGuideMode(name: string): void {
    this.cookieService.set('guide-' + name, 'true', 365);
  }

  getGuideMode(name: string) {
    return this.cookieService.get('guide-' + name);
  }

  removeGuideMode(name: string): void {
    this.cookieService.delete('guide-' + name);
  }
}
