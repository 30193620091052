import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isUz } from '../../../config/ui-config';

@Injectable({
  providedIn: 'root',
})
export class uzGuard {
  constructor(private router: Router) {}

  canActivate(): boolean {
    if (isUz) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
