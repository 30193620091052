import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  private _titleObserver = new BehaviorSubject<string>('');
  private _navigateToObserver = new BehaviorSubject<string>('');

  public updateTitle(title: string, extention?: string, navigateTo?: string) {
    const newTitle = extention ? `${title} - ${extention}` : title;
    this.titleService.setTitle(newTitle);
    this._titleObserver.next(title);
    this._navigateToObserver.next(navigateTo);
  }

  public get titleObserver(): BehaviorSubject<string> {
    return this._titleObserver;
  }
  public get navigateToObserver(): BehaviorSubject<string> {
    return this._navigateToObserver;
  }

  constructor(private titleService: Title) {}
}
