import { Injectable, Inject } from '@angular/core';
import {
  Router,
  NavigationEnd,
  UrlTree,
  NavigationCancel,
  ActivatedRoute,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { filter, tap, map } from 'rxjs/operators';
import { GoogleAnalyticsService } from './google-analytics.service';
import { CoreLibConfig } from '../core.module';
import { PageTitleService } from './page-title.service';
import { SidebarStateService } from './sidebar-state.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class RoutingStateService {
  private history: string[] = [];
  private canceledURL: string;
  private loaded: boolean;

  private _trackGoogleAnalitics: boolean;
  private _MENU_TREE: boolean;

  constructor(
    private router: Router,
    private gtmService: GoogleAnalyticsService,
    private titleService: PageTitleService,
    @Inject('CONFIG') private config: CoreLibConfig,
    private sidebarService: SidebarStateService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {}

  loadRouting(route = ''): void {
    if (this.loaded) {
      return;
    }
    this.router.events
      .pipe(
        tap((event) => {
          if (event instanceof NavigationCancel) {
            this.canceledURL = event.url;
          }
        }),
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
          const [mainPath] = event.urlAfterRedirects.split('?');

          return {
            url: event.url,
            urlAfterRedirects: event.urlAfterRedirects,
            mainPath,
          };
        }),
      )
      .subscribe((event) => {
        if (route) {
          this.redirectToValidPackageRoute(route, event);
        }
        this.history.unshift(event.urlAfterRedirects);
        this.history.length = 4;
        if (this._trackGoogleAnalitics) {
          this.gtmService.pageView(event.url);
        }
        if (this._MENU_TREE) {
          this.findAndSetPageTitleRecursive(this._MENU_TREE, event.mainPath);
        }

        this.tagHotjarPage();
      });
    this.loaded = true;
  }

  redirectToValidPackageRoute(route: string, event) {
    const isNotValid = event.url.includes('/packages');
    if (isNotValid) {
      const url = event.url.replace('packages', route);
      this.router.navigate([url]);
    }
  }
  private tagHotjarPage() {
    const lastRouteEventName = this.findLastChildData(
      this.activatedRoute.snapshot,
      'hotjarEventName',
    );
    lastRouteEventName ? this.tagHotjarEvent(lastRouteEventName) : null;
  }

  public tagHotjarEvent(eventName: string) {
    try {
      this.getHotjarInstance()('event', eventName);
    } catch {
      console.error('op => hotjar tag failed');
    }
  }

  private findLastChildData(
    route: ActivatedRouteSnapshot,
    dataName: string,
  ): any {
    if (!route) return null;
    const curRouteData = route.data[dataName] ?? null;
    return this.findLastChildData(route.firstChild, dataName) ?? curRouteData;
  }

  trackGoogleAnalitics(): void {
    this._trackGoogleAnalitics = !!this.config.gtagId;
  }

  updatePageTitle(menuTree: any): void {
    this._MENU_TREE = menuTree;
  }

  public resetPageTitle(): void {
    this.findAndSetPageTitleRecursive(
      this._MENU_TREE,
      this.history[0].split('?')[0],
    );
  }

  private findAndSetPageTitleRecursive(menuTree, routePath: string) {
    if (routePath.startsWith('/login')) {
      this.titleService.updateTitle(this.translate.instant('GENERAL.OPTIMO'));
      return false;
    }
    return menuTree.find((item) => {
      if (routePath.startsWith(item.path)) {
        const parentPaths = item.path.split('/') || [];
        const childrenPaths = this.router.url.split('/') || [];
        if (parentPaths.length === childrenPaths.length) {
          this.titleService.updateTitle(
            this.translate.instant(item.pageName || item.text),
            this.translate.instant('GENERAL.OPTIMO'),
          );
        }
        this.sidebarService.updateActiveMenuItem(item);
        return false;
      } else if (item.children) {
        this.findAndSetPageTitleRecursive(item.children, routePath);
        return false;
      }
      return false;
    });
  }

  getHistory(): string[] {
    return this.history;
  }

  getPreviousUrl(): string {
    return this.history[1];
  }

  removePreviousUrl(): string {
    return this.history.shift();
  }

  getPreviousUrlTree(): UrlTree {
    const url = this.getPreviousUrl();
    return url && this.router.parseUrl(url);
  }

  getPreviousCanceledUrl(): string {
    return this.canceledURL;
  }

  getPreviousCanceledUrlTree(): UrlTree {
    const url = this.getPreviousCanceledUrl();
    return url && this.router.parseUrl(url);
  }

  getHotjarInstance(): (event: string, args: any) => void {
    return window['hj'];
  }

  public trackHotjar(eventName: string): void {
    try {
      window['hj']('event', eventName);
    } catch {
      console.error;
    }
  }
}
