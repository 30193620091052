import { Component } from '@angular/core';
import { isUz } from '../../../config/ui-config';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  standalone: true,
  imports: [NgIf],
})
export class VideoDialogComponent {
  public isUz = isUz;
}
