import { Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SEOService } from '../shared/services/SEO.service';
import { isUz, phoneNumber } from '../../config/ui-config';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '../icon/icon.module';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  imports: [
    TranslateModule,
    CommonModule,
    SharedModule,
    NgSelectModule,
    ReactiveFormsModule,
    IconModule,
    RouterLink,
  ],
  standalone: true,
})
export class FooterComponent {
  public currentYear = new Date().getFullYear();
  public isUz = isUz;
  public phoneNumber = phoneNumber;
  constructor(
    public translateService: TranslateService,
    private seoService: SEOService,
  ) {}

  onPhoneNumber(): void {
    this.seoService.pushToDataLayer({
      event: 'phoneNumberClick',
      'event-uid': 'a473548b-289c-4b21-9d9c-6ad3e29dcf11',
      component: 'footer',
    });
  }
}
