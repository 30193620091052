import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';

import { provideClientHydration } from '@angular/platform-browser';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouter, RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './app/landing/landing.component';
import { defaultLanguage, isUz } from './config/ui-config';
import { FaqResolver } from './app/faq/faq.resolver';
import { uzGuard } from './app/shared/guard/uz-project.guard';
import { HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translateServerLoaderFactory } from './app/core/helpers/loaders/translate-server.loader';
import { CoreModule } from '@optimo/core';
import { environment } from './environments/environment';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    data: {
      title: !isUz
        ? 'PageTitleAndMetaTags.MainPage.title'
        : 'PageTitleAndMetaTags.MainPage.titleUz',
      meta: !isUz
        ? 'PageTitleAndMetaTags.MainPage.meta'
        : 'PageTitleAndMetaTags.MainPage.metaUz',
    },
  },
  {
    path: ':language',
    component: LandingComponent,
    data: {
      title: !isUz
        ? 'PageTitleAndMetaTags.MainPage.title'
        : 'PageTitleAndMetaTags.MainPage.titleUz',
      meta: !isUz
        ? 'PageTitleAndMetaTags.MainPage.meta'
        : 'PageTitleAndMetaTags.MainPage.metaUz',
    },
  },
  {
    path: ':language/faq/:categorySlug',
    loadChildren: () => import('./app/faq/faq.module').then((m) => m.FaqModule),
    data: {
      title: 'PageTitleAndMetaTags.FaqPage.title',
      meta: 'PageTitleAndMetaTags.FaqPage.meta',
    },
    resolve: {
      FAQs: FaqResolver,
    },
  },
  {
    path: isUz
      ? ':language/dokonni-avtomatlashtirish-paketlar'
      : ':language/magaziis-programa-paketebi',
    loadChildren: () =>
      import('./app/packages/packages.module').then((m) => m.PackagesModule),
    data: {
      title: 'PageTitleAndMetaTags.PackagesPage.title',
      meta: 'PageTitleAndMetaTags.PackagesPage.meta',
    },
  },
  {
    path: ':language/request-demo',
    loadChildren: () =>
      import('./app/demo/demo.module').then((m) => m.DemoModule),
    data: {
      title: 'PageTitleAndMetaTags.RequestDemoPage.title',
      meta: 'PageTitleAndMetaTags.RequestDemoPage.meta',
    },
  },
  {
    path: ':language/offers',
    canActivate: [uzGuard],
    loadChildren: () =>
      import('./app/offers/offers.module').then((m) => m.OffersModuleModule),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translateServerLoaderFactory,
        },
        defaultLanguage: defaultLanguage,
      }),
    ),
    importProvidersFrom(
      CoreModule.forRoot({
        mainRoute: environment.API_ENDPOINT,
        gtagId: environment.GTAG_ID,
        isProduction: environment.production,
        appType: environment.APP_TYPE,
      }),
    ),
    importProvidersFrom(
      RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        scrollOffset: [0, 90],
        initialNavigation: 'enabledBlocking',
      }),
    ),
    provideRouter(routes),
    provideClientHydration(),
    provideStore(),
    provideEffects(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    FaqResolver,
  ],
};
