import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import * as ka from './../../../../assets/i18n/ka.json';
import * as ru from './../../../../assets/i18n/ru.json';
import * as ru_uz from './../../../../assets/i18n/ru_uz.json';
import * as en from './../../../../assets/i18n/en.json';
import * as uz from './../../../../assets/i18n/uz.json';
import { isUz } from '../../../../config/ui-config';

export class TranslateServerLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return new Observable((observer) => {
      let jsonData: any;
      switch (lang) {
        case 'ka':
          jsonData = ka;
          break;
        case 'uz':
          jsonData = uz;
          break;
        case 'en':
          jsonData = en;
          break;
        case 'ru':
          jsonData = !isUz ? ru : ru_uz;
          break;
      }

      observer.next(jsonData);
      observer.complete();
    });
  }
}

export function translateServerLoaderFactory() {
  return new TranslateServerLoader();
}
